import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  
  //{ path: 'home', loadChildren: './home/home.module#HomePageModule'},
  { path: '', redirectTo: 'tab1', pathMatch: 'full' },
  {path: '',loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)},
  {path: 'alumno',loadChildren: () => import('./alumno/alumno.module').then( m => m.AlumnoPageModule)},
  {path: 'agregaralumno',loadChildren: () => import('./agregaralumno/agregaralumno.module').then( m => m.AgregaralumnoPageModule)},
  { path: 'agregaralumno/:id/:nombre/:carrera/:matricula/:direccion/:telefono', loadChildren: './agregaralumno/agregaralumno.module#AgregaralumnoPageModule'},
  {path: 'mostraralumno',loadChildren: () => import('./mostraralumno/mostraralumno.module').then( m => m.MostraralumnoPageModule)},
  {path: 'mostraralumno/:id/:nombre/:carrera/:matricula/:direccion/:telefono', loadChildren: './mostraralumno/mostraralumno.module#MostraralumnoPageModule'},
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
